/*
 * shop-catalog-layouts.scss
 * -----------------------------------------------
*/

table.tbl-shopping-cart, table.cart-total {
	.product-thumbnail {
		min-width: 64px;
	}

	img {
		width: 64px;
		box-shadow: none;
	}

	th,
	td {
		vertical-align: middle;
		border-left: 1px solid #e3e3e3;
		padding: 20px 30px;
	}
	.product-name {
		a {
			color: var(--headings-color);
		}
		.variation {
			font-size: 0.9rem;
			list-style: none;
		}
	}

	.product-remove a {
		display: inline-block;
		width: 24px;
		height: 24px;
		line-height: 24px;
		border-radius: 24px;
		transition: all 300ms ease-out;
		background-color: #757575;
		color: #ffffff;
		text-align: center;
	}
	.coupon-form {
		.apply-button {
			position: relative;
			display: inline-block;
			color: #1e2434;
			background: #f4f5f4;
			padding: 15px 29px;
			cursor: pointer;
			text-transform: uppercase;
			transition: all 500ms ease;
			&:hover {
				color: var(--text-color-bg-theme-color2);
				background-color: var(--theme-color2);
			}
		}
	}
}
table.tbl-shopping-cart > thead > tr > th,
table.tbl-shopping-cart > tbody > tr > th,
table.tbl-shopping-cart > tfoot > tr > th	{
	color: #444;
}