
/*** 

====================================================================
Main Header
====================================================================

***/

.header-span {
    position: relative;
    height: 110px;
    display: block;
    width: 100%;
}

.main-header {
    position: relative;
    width: 100%;
    z-index: 999;
}

.header-top {
    position: relative;
    display: flex;
    background: var(--bg-theme-color2);
    .inner-container{
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
    }
    .top-left { 
        position: relative;
        display: flex;
        align-items: center;
        padding: 10px 0;
        .text{
            font-size: 14px;
            color: #ffffff;
            font-weight: 500;
            line-height: 20px;
        }
    }
    .top-right {
        position: relative;
        display: flex;
        align-items: center;
    }

    .useful-links{
        display: flex;
        align-items: center;
        padding: 10px 0;
        margin-right: 30px;
        li{
            position: relative;
            padding-left: 15px;
            margin-left: 15px;
            font-size: 14px;
            color: #ffffff;
            line-height: 20px;
            &:first-child{
                margin-left: 0;
                padding-left: 0;
            }
            &:before{
                position: absolute;
                left:-3px;
                top: 0;
                content: "|";
            }
            a{
                color: #ffffff;
                transition: all 300ms ease;
                &:hover{
                    color: var(--theme-color1);
                }
            }
        }
    }
    .outer-box{
        min-width: 210px;
        display: flex;
        justify-content: flex-end;
    }
}

.main-header .header-lower {
    position: relative;
    transition: all 300ms ease;
    .logo-box {
        position: relative;
        padding: 24px 0;
        min-width: 280px;
    }
}

.main-header {
    width: 100%;
    z-index: 999;
    .logo {
        position: relative;
        display: block;

        img {
            max-width: 100%;
            height: auto;
        }
    }
    .main-box {
        position: relative;
        left: 0px;
        top: 0px;
        display: flex;
        align-items: center;
        transition: all 300ms ease;
        .nav-outer {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: space-between;
            width: 100%;
        }
    }
}

.main-menu{
    position:relative;
}

.main-menu .navbar-header{
    display: none;
}

.main-menu .navbar-collapse{
    padding:0px;    
}

.main-menu .navigation{
    position:relative;
    margin:0px;
}

.main-menu .navigation > li{
    position:relative;
    float:left;
    padding:25px 0px;
    margin-right: 60px;
    transition:all 300ms ease;
    &:last-child {
        margin-right: 0;
    }
    &:before {
        position: absolute;
        left:50%;
        bottom: 23px;
        height: 2px;
        width: 0%;
        background-color: var(--bg-theme-color2);
        content: "";
        transition: all 300ms ease;
    }
    &:hover:before,
    &.current:before {
        left: 0;
        width: 100%;
    }
    >a{
        position:relative;
        display:block;
        text-align:center;
        opacity:1;
        color: #7d8185;
        font-size:18px;
        line-height:30px;
        font-weight:500;
        padding: 0;
        display: flex;
        transition:all 300ms ease;
        .icon {
            position: relative;
            font-size: 22px;
            line-height: 24px;
            margin-left: 10px;
        }
        &:hover{
            color: var(--theme-color2);
        }
    }
    &.current>a {
        color: var(--theme-color2);
        font-weight: 700;
    }
    &.dropdown>a {
        padding-right: 14px;
        margin-right: -14px;
    }
    &.dropdown>a:after {
        content: "\f107";
        position: absolute;
        right: 0;
        top: 50%;
        width: 10px;
        height: 20px;
        display: block;
        line-height: 24px;
        font-size: 12px;
        z-index: 5;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        margin-top: -2px;
        transform: translateY(-50%);
    }
    &.dropdown:hover>ul {
        visibility: visible;
        opacity: 1;
        top: 100%;
        margin-top: 0;
        transition: all 300ms ease;
    }
}

.main-menu .navigation > li > ul{
    position: absolute;
    left: 0px;
    top: 100%;
    width: 220px;
    z-index: 100;
    padding: 10px 0 0;
    background-color: #ffffff;
    margin-top: 30px;
    opacity: 0;
    display: none;
    box-shadow: 0 0 3px rgb(0 0 0 / 10%);
    box-shadow: 2px 2px 5px 1px rgb(0 0 0 / 5%), -2px 0px 5px 1px rgb(0 0 0 / 5%);
    &.from-right{
        left:auto;
        right:0px;  
    }
    >li{
        position:relative;
        width:100%;
        border-bottom: 1px solid #ebf1f5;
        &:last-child {
            border-bottom: none;
        }
        >a{
            position:relative;
            display:block;
            padding:10px 0px;
            line-height:29px;
            font-weight:400;
            font-size:16px;
            color:var(--bg-theme-color1);
            text-align:left;
            margin: 0 30px;
            text-transform:capitalize;
            transition:all 200ms ease;
        }
        &:hover > a{
            color:var(--theme-color2);
        }
        &.dropdown > a:after{
            font-family: 'Font Awesome 5 Free';
            content: "\f105";
            position:absolute;
            right:0;
            top:11px;
            display:block;
            line-height:24px;
            font-size:11px;
            font-weight:900;
            z-index:5;  
        }
        &.dropdown:hover>ul {
            visibility: visible;
            opacity: 1;
            top: 0px;
            margin-top: 20px;
            transition: all 300ms ease;
        }
    }
}

.main-menu .navigation > li > ul > li > ul{
    position:absolute;
    left:100%;
    top:0px;
    width:220px;
    z-index:100;
    display:none;
    background-color:#ffffff;
    opacity:0;
    padding: 10px 0 0;
    margin-top: 10px;
    transform:translateY(-30px);
    box-shadow:2px 2px 5px 1px rgba(0,0,0,0.05),-2px 0px 5px 1px rgba(0,0,0,0.05);
    > li{
        position:relative;
        border-bottom: 1px solid #ebf1f5;
        width:100%;
        &:last-child{
            border-bottom:none; 
        }
        > a{
            position:relative;
            display:block;
            padding:10px 0;
            line-height:24px;
            font-weight:400;
            font-size:16px;
            color:var(--theme-color1);
            text-align:left;
            margin: 0 30px;
            text-transform:capitalize;
            transition:all 300ms ease;
            &:hover{
                color:var(--theme-color2);
            }
        }
    }
}

.main-menu .navigation li.dropdown .dropdown-btn{
    position:absolute;
    right:10px;
    top:8px;
    width:34px;
    height:30px;
    border:1px solid #ffffff;
    text-align:center;
    font-size:16px;
    line-height:26px;
    color:#ffffff;
    cursor:pointer;
    z-index:5;
    display:none;
}

.main-header .main-box .outer-box {
    position: relative;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
    padding: 10px 0;
    .theme-btn{
        line-height: 24px;
        padding: 15px 60px;
        margin-left: 30px;
    }
    .ui-btn-outer{
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 6px 0;
        margin-left: 40px;
        border-left: 1px solid #e6e8ed;
    }
}


.main-header .ui-btn {
    position: relative;
    display: block;
    height: 30px;
    width: 30px;
    line-height: 30px;
    text-align: center;
    background: none;
    font-size: 26px;
    color: var(--theme-color1);
    margin-left: 30px;
    transition: all 300ms ease;
    &:hover {
        color: var(--theme-color2);
    }
}

.main-header .info-btn {
    position: relative;
    display: block;
    font-size: 18px;
    padding: 8px 0;
    padding-left: 65px;
    color: var(--theme-color1);
    text-align: left;
    font-weight: 600;
    line-height: 20px;
    small {
        font-size: 14px;
        font-weight: 400;
        color: #7d8185;
    }
    i {
        position: absolute;
        left: 0;
        top: 50%;
        margin-top: -27px;
        line-height: 55px;
        height: 55px;
        width: 55px;
        text-align: center;
        border-radius: 50%;
        color: #fff;
        background-color: var(--bg-theme-color2);
        font-size: 18px;
    }
    &:hover{
        color: var(--bg-theme-color2);
    }
}


/*** 

====================================================================
Header Style One
====================================================================

***/

.header-style-one {
    .main-box {
        padding-right: 40px;
        padding-left: 0;
        box-shadow: 0 10px 60px rgba(0, 0, 0, .10);
    }
    .logo-box {
        position: relative;
        min-width: 375px;
        min-height: 90px;
        padding: 20px 40px;
        display: flex;
        align-items: center;
    }
}

/*** 

====================================================================
Header Style TWo
====================================================================

***/

.header-style-two{
    .header-upper{
        position: relative;
        .inner-container{
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .logo-box{
            .logo{
                padding: 21px 0;
            }
        }
    }
    .contact-info-outer{
        display: flex;
        align-items: center;
        li{
            position: relative;
            padding-left: 25px;
            margin-left: 25px;
            &:before{
                position: absolute;
                left: 0;
                top: 0;
                height: 100%;
                width: 1px;
                background-color: #dae6e6;
                transform: rotate(10deg);
                content: "";
            }
            &:first-child{
                &:before{
                    display: none;
                }
            }
        }        
    }
    .header-lower{
        position: absolute;
        left: 0;
        top: 100%;
        width: 100%;
        background-color: transparent;
        .main-box{
            background-color: var(--bg-theme-color3);
            padding-left: 50px;
            .outer-box{
                padding: 0;
                .ui-btn-outer{
                    border-left: 1px solid rgba(255, 255, 255, .20);
                    padding-right: 30px;
                    .ui-btn{
                        color: #fff;
                        font-size: 22px;
                        line-height: 35px;
                        font-weight: 400;
                        margin-left: 20px;
                    }
                }
                .theme-btn{
                    padding: 15px 60px;
                    line-height: 30px;
                    margin-left: 0;
                }
            }
        }
        .main-menu .navigation>li{
            padding: 15px 0;
            &:before{
                display: none;
            }
            >a{
                color: #f0f2f4;
                font-weight: 400;
            }
            &:hover > a,
            &.current>a {
                color: #ffffff;
            }
        }
    }
}

.contact-info-box{
    position: relative;
    padding-left: 54px;
    .icon {
        position: absolute;
        left: 0;
        top: 0;
        font-size: 34px;
        line-height: 50px;
        color: var(--theme-color2);
    }
    .title {
        display: block;
        font-size: 12px;
        line-height: 20px;
        color: #7d8185;
        font-weight: 500;
    }
    .text{
        display: block;
        font-size: 14px;
        line-height: 20px;
        color: var(--theme-color1);
        font-weight: 700;
    }
}


/*** 

====================================================================
Sticky Header
====================================================================

***/

.sticky-header {
    position: fixed;
    visibility: hidden;
    opacity: 0;
    left: 0px;
    top: 0px;
    width: 100%;
    padding: 0px 0px;
    z-index: 99999;
    background: #ffffff;
    -webkit-box-shadow: 0 0 20px rgba(0,0,0,.05);
    box-shadow: 0 0 20px rgba(0,0,0,.05);
}

.sticky-header.fixed-header{
    opacity:1;
    z-index: 9999;
    visibility:visible;
}

.sticky-header{
    .logo {
        padding: 10px 0;
        img {
            max-height: 40px;
        }
    }
    .nav-outer {
        position: relative;
        background: none;
    }
    .inner-container {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
}


.sticky-header .main-menu .navigation > li{
    margin: 0;
    margin-left: 40px;
    padding: 20px 0;
}

.sticky-header .main-menu .navigation > li > a{
    color: #707070;
}

.sticky-header .main-menu .navigation > li.current > a, 
.sticky-header .main-menu .navigation > li:hover > a{
    color: var(--theme-color2);
}

.sticky-header .main-menu .navigation>li:before{
    display: none;
}

.sticky-header .outer-box,
.sticky-header .navbar-header {
    display: none;
}

.sticky-header .mobile-nav-toggler {
    color: var(--theme-color1);
}