/*** 

====================================================================
  Search Popup
====================================================================

***/
.search-popup {
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: all 500ms ease;
  overflow: hidden;
  transform: scale(.95);
  .search-back-drop {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-color: var(--bg-theme-color1);
    opacity: .95;
  }
  .close-search {
    position: absolute;
    top: 30px;
    right: 30px;
    font-size: 26px;
    color: var(--theme-color2);
    z-index: 3;
    border-radius: 50%;
    background-color: transparent;
    transition: all 500ms ease;
  }
  .search-inner {
    position: relative;
    display: block;
    top: 40%;
    height: auto;
    z-index: 1;
    width: calc(100% - 60px);
    max-width: 800px;
    margin: auto;
    opacity: 0;
    transform: translateY(-50px);
    transition: all 300ms ease;
  }
  .form-group {
    position: relative;
    display: flex;
    align-items: center;
    input[type="search"],
    input[type="text"] {
      position: relative;
      display: block;
      line-height: 20px;
      font-size: 16px;
      width: 100%;
      height: 50px;
      border: 1px solid #e1e6dc;
      padding: 15px 20px;
      color: #707070;
      background: #ffffff;
      border-radius: 5px;
      transition: all 500ms ease;
      &:focus {
        border-color: var(--border-theme-color2);
      }
    }
    button {
      position: absolute;
      right: 5px;
      top: 5px;
      height: 40px;
      width: 40px;
      display: block;
      font-size: 14px;
      color: #fff;
      line-height: 40px;
      border-radius: 5px;
      font-weight: normal;
      background: var(--theme-color2);
      transition: all 500ms ease;
      &:hover {
        background: var(--theme-color2);
        color: #ffffff;
      }
    }
  }
  textarea,
  input {
    &::placeholder {
      color: inherit;
    }
  }
}

.moblie-search-active {
  .search-popup {
    opacity: 1;
    visibility: visible;
    transform: scale(1);
    border-radius: 0%;

    .search-inner {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 500ms;
    }
  }
}