/* FA svg css */
@import "../../../public_html/resources/font-awesome-pro/css/svg-with-js.css";
svg.svg-inline--fa {
    fill: currentColor;

}

svg.svg-inline {
    fill: currentColor;
}
