/*** 

====================================================================
  Main Slider
====================================================================

***/

.main-slider {
  position: relative;
}

.main-slider {
  .title {
    position: relative;
    display: inline-block;
    line-height: 30px;
    font-size: 16px;
    color: #7d8185;
    font-weight: 700;
    text-transform: uppercase;
    letter-spacing: .1em;
    &:before{
      position: absolute;
      left: 0;
      top:100%;
      height: 15px;
      width: 221px;
      background: url(../images/main-slider/icon/icon-lines.png) center no-repeat;
      content: "";
    }
  }
  h1{
    position: relative;
    display: block;
    line-height: .90em;
    letter-spacing: -.04em;
  }
  .style-font{
    display: inline-block;
    color: var(--theme-color4);
    font-weight: 400;
  }
  .text{
    font-size: 18px;
    line-height: 30px;
  }
  .btn-box {
    position: relative;
  }
  .theme-btn {
    display: inline-block;
    white-space: nowrap;
    min-width: 200px !important;
  }

  &.style-two{
    h1{
      letter-spacing: -.08em;
      line-height: 1em;
    }
    .main-image{
      padding: 30px;
      img{
        border: 10px solid #fff;
        box-shadow: 0 10px 20px rgba(0,0,0,0.20);
      }
    }
  }
}


.tp-dottedoverlay {
  background-color: #222429;
  opacity: 0;
}

.tp-bannertimer {
  display: none;
}
