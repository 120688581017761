/*** 

====================================================================
		FAQ's Sectiom
====================================================================

***/

.faqs-section {
    position: relative; 
    background-color: #f2f8f8;
    .faq-column {
       position: relative;
       .inner-column {
           position: relative;
           padding: 120px 0 120px 0;
           padding-right: 0;
       }
       .sec-title{
        margin-bottom: 40px;
       }
   }

   .image-column{
        position: relative;
        margin-bottom: 80px;
        .inner-column{
            position: relative;
            margin-left: -580px;
            padding-right: 80px;
        }
        .image{
            margin-bottom: 0;
            img{
                filter: drop-shadow(0 15px 60px rgba(0,0,0,.08));
            }
        }
   }
}


.accordion-box {
    position: relative;
    .block {
        position: relative;
        background-color: #ffffff;
        border: 1px solid #dae6e6;
        border-radius: 25px;
        margin-bottom: 10px;
        .acc-btn {
            position: relative;
            font-size: 18px;
            line-height: 26px;
            color: var(--theme-color1);
            font-weight: 700;
            cursor: pointer;
            padding: 16px 30px;
            padding-right: 70px;
            transition: all 500ms ease;
            .icon {
                position: absolute;
                right: 30px;
                top: 16px;
                height: 26px;
                font-size: 18px;
                line-height: 26px;
                color: var(--theme-color1);
                transition: all 500ms ease;
            }
            &.active {
                color: var(--theme-color2);
                .icon {
                    color: var(--bg-theme-color1);
            
                    &:before {
                        content: "\f107";
                        color: var(--theme-color2);
                    }
                }
            }
        }
        .acc-content {
            position: relative;
            display: none;
            .content {
                position: relative;
                padding: 0px 30px 25px;
                .text {
                    display: block;
                    font-size: 16px;
                    line-height: 30px;
                    color: #7d8185;
                    margin-bottom: 0;
                }
            }

            &.current {
                display: block;
                box-shadow: 0 10px 60px rgba(0, 0, 0, .07);
            }
        }
    }
}


.contact-form {
    position: relative;
    background-color: #2c2e33;
    padding: 55px 55px 55px;
    border-top: 5px solid var(--border-theme-color2);
    .title-box{
        position: relative;
        margin-bottom: 25px;
        .sub-title{
            display: block;
            font-size: 12px;
            color: var(--theme-color2);
            font-weight: 700;
            text-transform: uppercase;
            letter-spacing: .1em;
            margin-bottom: 2px;
        }
        h3{
            font-size: 34px;
            color: #fff;
            font-weight: 700;
        }
    }
    .form-group {
        position: relative;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        label {
            font-size: 16px;
            line-height: 20px;
            color: #ffffff;
            font-weight: 500;
            display: block;
            letter-spacing: 1px;
            margin-bottom: 15px;
            &.error {
                display: block;
                font-weight: 500;
                font-size: 13px;
                text-transform: capitalize;
                line-height: 24px;
                color: #ff0000;
                margin-bottom: 0;
            }
        }
        .select2-container--default .select2-selection--single,
        input:not([type="submit"]),
        textarea,
        select {
            position: relative;
            display: block;
            height: 54px;
            width: 100%;
            padding: 15px 30px;
            font-size: 14px;
            color: #fff;
            line-height: 22px;
            font-weight: 400;
            background-color: #2c2e33;
            border: 1px solid rgba(255, 255, 255, .15);
            margin-bottom: 0;
            transition: all 300ms ease;
        }

        ::-webkit-input-placeholder{color: #8c8f94;}
        ::-moz-input-placeholder{color: #8c8f94;}
        ::-ms-input-placeholder{color: #8c8f94;}

        input:focus,
        select:focus,
        textarea:focus {
            border-color: var(--border-theme-color2);
        }

        textarea {
            height: 100px;
            resize: none;
        }

        input[type="submit"],
        button {
            margin-top: 5px;
            text-transform: uppercase;
        }
    }

    &.light {
        padding: 45px 55px 55px;
        background-color: #ffffff;
        box-shadow: 0 10px 60px rgba(0, 0, 0, .07);
        &:before{
            position: absolute;
            right: 0;
            bottom: 0;
            width: 223px;
            height: 264px;
            background-image: url(../images/icons/shape-5.png);
            content: "";
        }
        .select2-container--default .select2-selection--single,
        input:not([type="submit"]),
        textarea,
        select {
            background-color: #f4f5f8;
            color: var(--theme-color1);
        }
        .title-box h3{
            color: var(--theme-color1);
        }
        textarea {
            height: 110px;
            resize: none;
        }
    }
}