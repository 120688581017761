/*** 

====================================================================
		SignUp Sectiom
====================================================================

***/

.signup-section {
    position: relative;
    &:before{
        @include overlay;
        @include absolute;
        max-height: 190px;
        background-color: #f1f7f7;
        z-index: -1;
        content: "";
    }
    .icon-paper-clip{
        top: -50px;
        left: -140px;
        z-index: 1;
    }
    .float-icon{
        position: absolute;
        right: -90px;
        bottom: -210px;
        z-index: 0;
    }
    .outer-box{
        position: relative;
        @include background;
        padding: 120px 120px;
        border-radius: 30px;
        overflow: hidden;
    }
    .title-column {
        position: relative;

        .sec-title {
            .text{
                margin-top: 20px;
            }
        }
    }
    .form-column{
        .inner-column{
            margin-left: -20px;
        }
    }
}

.signup-form {
    position: relative;
    .form-group {
        position: relative;
        margin-bottom: 20px;
        &:last-child {
            margin-bottom: 0;
        }
        .select2-container--default .select2-selection--single,
        input:not([type="submit"]),
        textarea,
        select {
            position: relative;
            display: block;
            height: 60px;
            width: 100%;
            padding: 15px 40px;
            font-size: 16px;
            color: var(--theme-color1);
            line-height: 28px;
            font-weight: 400;
            background-color: #ffffff;
            border: 1px solid rgba(255, 255, 255, .15);
            border-radius: 50px;
            margin-bottom: 0;
            transition: all 300ms ease;
        }
        ::-webkit-input-placeholder{color: #7d8185;}
        ::-moz-input-placeholder{color: #7d8185;}
        ::-ms-input-placeholder{color: #7d8185;}
        input:focus,
        select:focus,
        textarea:focus {
            border-color: var(--border-theme-color2);
        }

        textarea {
            height: 100px;
            resize: none;
        }
        input[type="submit"],
        button {
            margin-top: 5px;
            text-transform: uppercase;
        }
    }
}

/*** 

====================================================================
	SignUp Sectiom Two
====================================================================

***/

.signup-section-two{
    position: relative;
    .icon-line-1{
        left: -230px;
        top: 30px;
    }
    .icon-line-2 {
        right: -120px;
        top: 110px;
    }
    .icon-line-3 {
        right: 120px;
        bottom: -25px;
    }
    .icon-square{
        left: -370px;
        bottom: 150px;
    }
    .icon-triangle{
        left:-210px;
        top: 340px;
    }
    .icon-dots-2 {
        right: -345px;
        top: 240px;
    }
    .icon-dots-6{
        right: -310px;
        bottom: -55px;
    }
    
    .title-column {
        position: relative;
        .inner-column{
            padding: 100px 0 140px;
            margin-right: -30px;
        }
        .sec-title {
            margin-bottom: 40px;
            .text {
                margin-top: 25px;
                line-height: 34px;
            }
        }
    }

    .form-column {
        .inner-column {
            padding-left: 65px;
            padding-bottom: 120px;
        }
        .signup-form-two{
            margin-top: -88px;
            overflow: hidden;
            &:before{
                position: absolute;
                right: -150px;
                bottom: -90px;
                width: 344px;
                height: 383px;
                background-image: url(../images/icons/pattern-3.png);
                content: "";
            }
        }
    }
}

.signup-form-two{
    position: relative;
    .title-box{
        position: relative;
        background-color: var(--bg-theme-color3);
        padding: 30px 55px;
        margin-top: -2px;
        &::before{
            position: absolute;
            left: 55px;
            top: 100%;
            border-left: 15px solid transparent;
            border-right: 15px solid transparent;
            border-top: 10px solid var(--border-theme-color3);
            content: "";
            z-index: 1;
        }
        .title{
            color: #ffffff;
            line-height: 30px;
            margin-bottom: 0;
        }
    }
    form{
        padding: 60px 55px;
        border: 2px solid #f1f1f1;
        border-top: 0;
    }
    .form-group {
        position: relative;
        margin-bottom: 10px;
        &:last-child {
            margin-bottom: 0;
        }

        .select2-container--default .select2-selection--single,
        input:not([type="submit"]),
        textarea,
        select {
            position: relative;
            display: block;
            height: 78px;
            width: 100%;
            padding: 25px 40px;
            font-size: 16px;
            color: var(--theme-color1);
            line-height: 26px;
            font-weight: 400;
            background-color: #f1f1f1;
            border: 1px solid rgba(255, 255, 255, .1);
            margin-bottom: 0;
            transition: all 300ms ease;
        }

        ::-webkit-input-placeholder {color: #7d8185;}
        ::-moz-input-placeholder {color: #7d8185;}
        ::-ms-input-placeholder {color: #7d8185;}
        input:focus,
        select:focus,
        textarea:focus {
            border-color: var(--border-theme-color2);
        }
        textarea {
            height: 100px;
            resize: none;
        }
        input[type="submit"],
        button {
            border-radius: 0;
            padding: 25px 60px;
            line-height: 26px;
            font-size: 15px;
            background-color: #f16101;
        }
    }
}