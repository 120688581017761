/***

==================================================================
    Event Section
==================================================================

***/

.event-section {
    position: relative;
    padding: 120px 0 90px;
    box-shadow: 0 10px 60px rgba(0,0,0, .05);
    .icon-dotted-map-3{
        left: -190px;
        top: 90px;
    }
    .sec-title{
        margin-bottom: 35px;
    }
}

.event-block{
    position: relative;
    margin-bottom: 30px;
    .inner-box{
        position: relative;
        background-color: #fff;
        padding: 40px 40px;
        box-shadow: 0 10px 60px rgba(0,0,0, .05);
        border-radius: 10px;
        transition: all 300ms ease;
        &:before {
            position: absolute;
            right: 0;
            bottom: 0;
            height: 183px;
            width: 135px;
            content: "";
            background-image: url(../images/icons/icon-event.png);
        }
        &:hover{
            transform: translateY(-10px);
            box-shadow: 0 5px 20px rgba(0, 0, 0, .15);
            .image-box{
                .image{
                    border-radius: 0 50% 0 50%;
                }
            }
        }
    }
    .image-box{
        @include absolute;
        .image{
            position: relative;
            margin-bottom: 0;
            height: 130px;
            width: 130px;
            border-radius: 50%;
            overflow: hidden;
            transition: all 300ms ease;
            img{
                width: 100%;
                height: 100%;
            }
        }
        .date{
            position: absolute;
            left: 90px;
            bottom: 10px;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: #6956f9;
            color: #fff;
            font-weight: 700;
            font-size: 12px;
            line-height: 1em;
        }
    }
    .content-box{
        position: relative;
        padding-left: 180px;
        padding-right: 30px;
        min-height: 130px;

    }
    .title{
        margin-bottom: 12px;
        &:hover{
            color: var(--theme-color2);
        }
    }
    .post-info {
        position: relative;
        li {
            position: relative;
            font-size: 15px;
            line-height: 24px;
            color: #7e7e7e;
            font-weight: 400;
            margin-bottom: 10px;
            span{
                color: #242323;
            }
            i {
                margin-right: 10px;
                color: var(--theme-color5);
                font-size: 14px;         
                font-weight: 700;       
            }
        }
    }
}