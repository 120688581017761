/*--------------------------------------------------------------
# Team Details
--------------------------------------------------------------*/
.team-details {
  position: relative;
  display: block;
}
.team-details__top {
  padding: 0 0 120px;
}
.team-details-shape-1 {
  position: absolute;
  bottom: -270px;
  right: 0;
  opacity: .50;
  z-index: 2;
  img {
    width: auto;
  }
}
.team-details__top-left {
  position: relative;
  display: block;
  margin-right: 20px;
}
.team-details__top-img {
  position: relative;
  display: block;
  border-radius: 30px;
  img {
    width: 100%;
    border-radius: 30px;
  }
}
.team-details__big-text {
  font-size: 80px;
  line-height: 80px;
  text-transform: uppercase;
  color: #eef0f6;
  letter-spacing: 0.35em;
  font-weight: 400;
  position: absolute;
  -webkit-transform: rotate(-90deg);
  transform: rotate(-90deg);
  top: 209px;
  left: -325px;
}
.team-details__top-right {
  position: relative;
  display: block;
  margin-left: 50px;
  @media only screen and (max-width: 991px){
    margin-top: 70px;
    margin-left: 0;
  }
}
.team-details__top-content {
  position: relative;
  display: block;
  margin-top: -11px;
}
.team-details__top-name {
  font-size: 40px;
  font-weight: 700;
  line-height: 50px;
  margin-bottom: 3px;
}
.team-details__top-title {
  font-size: 16px;
  color: var(--theme-color1);
}
.team-details__social {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 28px;
  a {
    position: relative;
    height: 40px;
    width: 40px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    background-color: var(--theme-color5);
    color: var(--text-color-bg-theme-color5);
    font-size: 15px;
    border-radius: 50%;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    &:hover {
      background-color: var(--theme-color2);
      color: var(--text-color-bg-theme-color2);
    }
  }
  a+a {
    margin-left: 10px;
  }
}
.team-details__top-text-1 {
  font-size: 30px;
  color: var(--theme-color1);
  line-height: 45px;
  font-weight: 400;
  margin-bottom: 30px;
}
.team-details__top-text-2 {
  padding-top: 23px;
  padding-bottom: 35px;
}
.team-details__top-text-3 {
}
.team-details__bottom {
  position: relative;
  display: block;
  border-top: 1px solid #e4e5ea;
  padding-top: 110px;
}
.team-details__bottom-left {
  position: relative;
  display: block;
  margin-right: 70px;
}
.team-details__bottom-left-title {
  font-size: 36px;
  font-weight: 700;
  line-height: 46px;
}
.team-details__bottom-left-text {
  padding-top: 30px;
}
.team-details__bottom-right {
  position: relative;
  display: block;
  margin-left: 70px;
  margin-top: 1px;
  @media only screen and (max-width: 991px){
    margin-left: 0;
  }
}
.team-details__progress {
  position: relative;
  display: block;
  width: 100%;
  .bar {
    position: relative;
    width: 100%;
    height: 13px;
    background-color: #eef0f6;
    border-radius: 7px;
    margin-bottom: 22px;
  }
  .bar-inner {
    position: relative;
    display: block;
    width: 0px;
    height: 13px;
    border-radius: 7px;
    background-color: var(--theme-color2);
    -webkit-transition: all 1500ms ease;
    transition: all 1500ms ease;
  }
  .count-text {
    position: absolute;
    right: 0px;
    bottom: 21px;
    color: var(--theme-color1);
    line-height: 24px;
    font-size: 14px;
    text-align: center;
    font-weight: 500;
    opacity: 0;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
  }
  .bar-inner.counted {
    .count-text {
      opacity: 1;
    }
  }
  .bar.marb-0 {
    margin-bottom: 0;
  }
}
.team-details__progress-single {
  position: relative;
  display: block;
}
.team-details__progress-title {
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  color: var(--theme-color1);
  margin-bottom: 6px;
}
.team-contact-form{
  background-color: #eef0f6;


  input[type="text"],
  input[type="email"] {
    background-color: #fff;
  }

  textarea {
    background-color: #fff;
    height: 180px;
  }

}
