
/*** 

====================================================================
  Anim Icons
====================================================================

***/

.anim-icons {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    height: 100%;
    width: 100%;
    max-width: 1170px;
    margin: 0 auto;
}

.anim-icons.full-width {
    max-width: 100%;
}

.anim-icons .icon {
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100%;
}

// Icons

.icon-dotted-map{
    width: 415px;
    height: 576px;
    background-image: url(../images/icons/icon-dotted-map.png);
}

.icon-dotted-map-2{
    width: 623px;
    height: 576px;
    background-image: url(../images/icons/icon-dotted-map-2.png);
}

.icon-dotted-map-3{
    width: 1492px;
    height: 723px;
    background-image: url(../images/icons/icon-dotted-map-3.png);
}

.icon-dotted-map-4 {
    width: 758px;
    height: 700px;
    background-image: url(../images/icons/icon-dotted-map-4.png);
}

.icon-dotted-line {
    width: 150px;
    height: 215px;
    background-image: url(../images/icons/icon-dotted-line.png);
}

.icon-paper-plan{
    width: 278px;
    height: 317px;
    background-image: url(../images/icons/icon-paper-plan.png);
}

.icon-e {
    width: 292px;
    height: 288px;
    background-image: url(../images/icons/icon-e.png);
}

.icon-group-1 {
    width: 433px;
    height: 696px;
    background-image: url(../images/icons/icon-group-1.png);
}

.icon-group-2 {
    width: 412px;
    height: 689px;
    background-image: url(../images/icons/icon-group-2.png);
}

.icon-paper-clip{
    width: 243px;
    height: 201px;
    background-image: url(../images/icons/icon-paper-clip.png);
}

.icon-pencil-line{
    width: 970px;
    height: 387px;
    background-image: url(../images/icons/icon-pencil-line.png);
}

.icon-calculator{
    width: 336px;
    height: 332px;
    background-image: url(../images/icons/icon-calculator.png);
}

.icon-pin-clip{
    width: 188px;
    height: 178px;
    background-image: url(../images/icons/icon-pin-clip.png);
}

.icon-percent{
    width: 214px;
    height: 213px;
    background-image: url(../images/icons/icon-percent.png);
}

.icon-idea{
    width: 194px;
    height: 194px;
    background-image: url(../images/icons/icon-idea.png);
}

.icon-dots{
    width: 214px;
    height: 214px;
    background-image: url(../images/icons/icon-dots.png);
}

.icon-dots-2{
    width: 196px;
    height: 266px;
    background-image: url(../images/icons/icon-dots-2.png);
}

.icon-dots-3{
    width: 113px;
    height: 60px;
    background-image: url(../images/icons/icon-dots-3.png);
}

.icon-dots-4{
    width: 141px;
    height: 145px;
    background-image: url(../images/icons/icon-dots-4.png);
}

.icon-dots-5{
    width: 135px;
    height: 139px;
    background-image: url(../images/icons/icon-dots-5.png);
}

.icon-dots-6{
    width: 109px;
    height: 108px;
    background-image: url(../images/icons/icon-dots-6.png);
}

.icon-dots-7{
    width: 253px;
    height: 246px;
    background-image: url(../images/icons/icon-dots-7.png);
}

.icon-line-1{
    width: 125px;
    height: 126px;
    background-image: url(../images/icons/icon-line-1.png);
}

.icon-line-2{
    width: 86px;
    height: 85px;
    background-image: url(../images/icons/icon-line-2.png);
}

.icon-line-3{
    width: 129px;
    height: 88px;
    background-image: url(../images/icons/icon-line-3.png);
}

.icon-star-1 {
    width: 38px;
    height: 39px;
    background-image: url(../images/icons/icon-star-1.png);
}

.icon-star-2{
    width: 38px;
    height: 39px;
    background-image: url(../images/icons/icon-star-2.png);
}

.icon-wave{
    width: 77px;
    height: 17px;
    background-image: url(../images/icons/icon-wave.png);
}

.icon-triangle{
    width: 28px;
    height: 24px;
    background-image: url(../images/icons/icon-triangle.png);
}

.icon-square{
    width: 58px;
    height: 57px;
    background-image: url(../images/icons/icon-square.png);
}

.icon-arrow{
    width: 223px;
    height: 105px;
    background-image: url(../images/icons/icon-arrow.png);
}

.icon-arrow-2 {
    width: 311px;
    height: 124px;
    background-image: url(../images/icons/icon-arrow-2.png);
}

.icon-shape-1{
    width: 557px;
    height: 557px;
    background-image: url(../images/icons/icon-shape-1.png);
}

.icon-shape-2{
    width: 751px;
    height: 850px;
    background-image: url(../images/icons/icon-shape-2.png);
}

// Animations

.bounce-y{
    animation: bounce-y 10s infinite linear;
}

.bounce-x{
    animation: bounce-x 10s infinite linear;
}

.spin-one{
    animation: fa-spin 10s infinite linear;
}

.zoom-one{
    animation: zoom-one 5s infinite linear;
}

.zoom-two {
    animation: zoom-two 10s infinite linear;
}

@keyframes float{
    0% {
        transform: translateY(0);
    }

    50% {
        transform: translateY(-10px);
    }

    100% {
        transform: translateY(0);
    }
}

@keyframes bounce-y {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-30px);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes bounce-x {
    0% {
        transform: translateX(0);
    }
    50% {
        transform: translateX(30px);
    }
    100% {
        transform: translateX(0);
    }
}

@keyframes zoom-one {
    0% {
        transform: scale(.95);
    }

    50% {
        transform: scale(1.00);
    }

    100% {
        transform: scale(.95);
    }
}

@keyframes zoom-two {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.05);
    }

    100% {
        transform: scale(1);
    }
}


.overlay-anim {
    position: relative;
    &:after {
        background: rgba(255, 255, 255, 0.3);
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 0;
        opacity: 1;
        z-index: 9;
        pointer-events: none;
    }
    &:hover{
        &:after {
            height: 100%;
            opacity: 0;
            transition: all 400ms linear;
        }
    }
    
}