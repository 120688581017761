
@import "common/root.scss";
@import "common/mixing.scss";
@import "common/loader.scss";

@import url('https://fonts.googleapis.com/css2?family=DM+Sans:ital,wght@0,400;0,500;0,700;1,400;1,500;1,700&family=Satisfy&family=Space+Grotesk:wght@300;400;500;600;700&display=swap');

@font-face {
    font-family: 'Reey';
    src: url('../fonts/Reey-Regular.woff2') format('woff2'),
        url('../fonts/Reey-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@import "plugins/animate";
@import "plugins/owl";
@import "plugins/swiper.min";
@import "plugins/jquery.fancybox.min";
@import "plugins/jquery-ui";
@import "plugins/linear";
@import "plugins/select2.min";
@import "plugins/fontawesome-all";
@import "plugins/flaticon";
@import "plugins/tm-bs-mp";
@import "plugins/tm-utility-classes";


@import "reset.scss";
@import "button.scss";
@import "animate-icon.scss";
@import "search-popup.scss";
@import "header.scss";
@import "mobile-menu.scss";
@import "banner.scss";
@import "section-title.scss";
@import "main-slider.scss";
@import "about.scss";
@import "courses.scss";
@import "features.scss";
@import "categories-current.scss";
@import "funfact.scss";
@import "faqs.scss";
@import "signup.scss";
@import "team.scss";
@import "video.scss";
@import "pricing.scss";
@import "gallery.scss";
@import "event.scss";
@import "call-to-action.scss";
@import "testimonials.scss";
@import "countdown.scss";
@import "news.scss";
@import "clients.scss";

@import "shop/loader.scss";


//inner
@import "inner/page-title.scss";
@import "inner/404.scss";
@import "inner/course-details.scss";
@import "inner/service-details.scss";
@import "inner/project-details.scss";
@import "inner/news-details.scss";
@import "inner/news-sidebar.scss";
@import "inner/news-comment.scss";
@import "inner/team-details.scss";
@import "inner/contact-page.scss";

@import "footer.scss";

@import "plugins/responsive";