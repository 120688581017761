/*** 

====================================================================
Reset
====================================================================

***/

*{
    margin:0px;
    padding:0px;
    border:none;
    outline:none;
    font-size: 100%;
}

/*** 

====================================================================
Global Settings
====================================================================

***/

textarea{
    overflow:hidden;    
    resize: none;
}

button{
    outline: none !important;
    cursor: pointer;
}

img {
    display: inline-block;
    max-width: 100%;
    height: auto;
}

ul,
li {
    list-style: none;
    padding: 0px;
    margin: 0px;
}

.title a{
    color: inherit;
}

.color1{color: var(--theme-color1);}
.color2{color: var(--theme-color2);}
.color3{color: var(--theme-color3);}
.color4{color: var(--theme-color4);}
.color5{color: var(--theme-color5);}


.style-font{font-family: var(--style-font);}


.page-wrapper {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 300px;
    overflow: hidden;
    z-index: 99;
    background-color: #ffffff;
}

.large-container {
    position: static;
    max-width:var(--large-container-width);
    padding: 0px 15px;
    margin: 0 auto;
    width: 100%;
}

.auto-container{
    position:static;
    max-width:var(--container-width);
    padding:0px 15px;
    margin:0 auto;
    width: 100%;
}

.small-container{
    position:static;
    max-width:var(--small-container-width);
    padding:0px 15px;
    margin: 0 auto;
    width: 100%;
}

.pull-right {
    float: right;
}

.pull-left {
    float: left;
}

.dropdown-toggle::after {
    display: none;
}

.bg-image {
    @include overlay;
    @include background;
}

/*=======================
    Preloader
=======================*/

.preloader {
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 100%;
    z-index: 999999;
    background-color: #ffffff;
}

.preloader:after {
    position: absolute;
    left: 50%;
    top: 50%;
    height: 100px;
    width: 100px;
    margin-left: -50px;
    margin-top: -60px;
    background-position: center center;
    background-repeat: no-repeat;
    background-image: url(../images/icons/preloader.gif);
    background-size: 60px;
    content: "";
}

.preloader:before {
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    width: 100%;
    text-align: center;
    margin: 0 auto;
    margin-top: 15px;
    color: var(--theme-color1);
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
    text-transform: uppercase;
    transition: none;
    content: "Loading";
}

/*=======================
Scroll To Top style
=======================*/

.scroll-to-top {
    position: fixed;
    right: 20px;
    bottom: 20px;
    width: 40px;
    font-size: 16px;
    line-height: 40px;
    color: #ffffff;
    text-align: center;
    cursor: pointer;
    background-color: var(--theme-color2);
    z-index: 100;
    display: none;
    border-radius: 50%;
    margin: 0 auto;
    -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.10);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.10);
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.scroll-to-top:hover {
    background: var(--theme-color1);
    color: #ffffff;
}

/*Social Icon One*/

.social-icon-one{
    position:relative;
    display: flex;
    flex-wrap: wrap;
    li{
        position:relative;
        a{
            position:relative;
            display:block;
            line-height: 40px;
            height: 40px;
            width: 40px;
            border-radius:50%;
            margin-right:10px;
            text-align: center;
            font-size:14px;
            color: var(--theme-color1);
            background-color: rgba(25, 30, 35, .10);
            transition:all 300ms ease;              
            &:hover{
                color: #ffffff;
                background-color: var(--bg-theme-color1);
            }
        }   
    }
    &.light{
        li{
            a{
                color: #ffffff;
                &:hover{
                    background-color: var(--bg-theme-color1);
                    color: #ffffff;
                }
            }
        }
    }
}



/*Social Icon Two*/

.social-icon-two{
    position: relative;
    display: flex;
    li {
        position: relative;
        display: flex;
        margin-left: 8px;
        &:first-child{margin-left: 0;}
        a {
            position: relative;
            display: block;
            line-height: 42px;
            height: 42px;
            width: 42px;
            border-radius: 50%;
            text-align: center;
            font-size: 14px;
            color: #ffffff;
            border-radius: 50%;
            background-color: #2a3037;
            transition: all 300ms ease;
            &:hover {
                color: #ffffff;
                background-color: var(--bg-theme-color2);
            }
        }
    }
}

/*Social Icon Three*/

.social-icon-three{
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.social-icon-three li {
    position: relative;
    margin-left: 20px;
}

.social-icon-three li a {
    position: relative;
    display: block;
    line-height: 40px;
    text-align: center;
    font-size: 15px;
    color: #2f3529;
    border-radius: 50%;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
}

.social-icon-three li a:hover {
    color: var(--theme-color2);
}

/*======================
    List Style One 
======================*/

.list-style-one {
    position: relative;
    margin-bottom: 30px;

    li {
        position: relative;
        padding-left: 70px;
        min-height: 50px;
        font-size: 20px;
        line-height: 30px;
        color: var(--theme-color1);
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 20px;
        padding-right: 20px;
        letter-spacing: -.01em;
        &:hover{
            .icon{
                transform: scale(-1) rotate(-180deg);
            }
        }
        .icon {
            @include absolute;
            @include flex-center;
            color: #fff;
            font-size: 18px;
            height: 50px;
            width: 50px;
            border-radius: 50%;
            background-color: var(--bg-theme-color5);
            box-shadow: 0 10px;
            transition: all 300ms ease;;
        }
        &:nth-child(4n + 2) .icon {
            background-color: var(--bg-theme-color3);
        }
        &:nth-child(4n + 3) .icon {
            background-color: var(--bg-theme-color4);
        }
        &:nth-child(4n + 4) .icon {
            background-color: var(--bg-theme-color2);
        }
    }
    &.two-column {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 50%;
        }
    }
}

/*======================
    List Style Two
======================*/

.list-style-two{
    position: relative;
    margin-bottom: 30px;
    li {
        position: relative;
        padding-left: 35px;
        font-size: 18px;
        line-height: 26px;
        color: var(--theme-color1);
        font-weight: 700;
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-bottom: 12px;
        padding-right: 20px;
        letter-spacing: -.01em;
        .icon {
            @include absolute;
            color: var(--theme-color2);
            font-size: 18px;
            line-height: 26px;
        }
    }

    &.two-column {
        display: flex;
        flex-wrap: wrap;

        li {
            width: 50%;
        }
    }
}

/*======================
    Tabs Box
======================*/

.tabs-box{
    position: relative;
}

.tabs-box .tab{
    display: none;
}

.tabs-box .active-tab{
    display: block;
}

/*======================
    Media Play Button 
======================*/

.play-now {
	position: relative;
	display: block;
	z-index: 9;
	transition: all 300ms ease;
}

.play-now .icon{
	position: relative;
	display: inline-block;
	height: 70px;
	width: 70px;
	text-align: center;
	line-height: 70px;
	background-color: #ffffff;
	color: var(--bg-theme-color1);
	z-index: 1;
	padding-left: 5px;
	font-size: 14px;
	display: block;
	border-radius: 50%;
	box-shadow: 0  0px 10px 0 rgba(255, 255, 255, .3);
	transform-origin:center; 
}

.play-now .ripple,
.play-now .ripple:before,
.play-now .ripple:after {
	position: absolute;
	top: 50%;
	left: 50%;
	height: 70px;
	width: 70px;
	transform: translate(-50%, -50%);
	border-radius: 50%;
	box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
	animation: ripple 3s infinite;
}

.play-now .ripple:before {
	-webkit-animation-delay: .9s;
	animation-delay: .9s;
	content: "";
	position: absolute;
}

.play-now .ripple:after {
	-webkit-animation-delay: .6s;
	animation-delay: .6s;
	content: "";
	position: absolute;
}

@-webkit-keyframes ripple {
	70% {-webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);}
	100% {-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
}

@keyframes ripple {
	70% {-webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);}
	100% {-webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);}
} 

.play-now-two{
    height: 92px;
    width: 92px;
    border-radius: 50%;
    @include flex-center;
    padding-left: 5px;
    font-size: 12px;
    border: .5px solid var(--border-theme-color1);
    color: #ffffff;
    transition: all 300ms ease;
    animation: zoom-one 3s infinite linear;
    &:before{
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        height: 62px;
        width: 62px;
        border-radius: 50%;
        background-color: var(--bg-theme-color1);
        content: "";
    }
    i{
        position: relative;
    }
    &:hover{
        color: #ff9205;
        background-color: #ffffff;
    }
}


/*========================
  Select2 Dropdown Plugin
========================*/

.select2-results__option {
    padding: 0 10px;
    color: #7c858c;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
    color: #7c858c;
    padding-left: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background-color: var(--bg-theme-color1);
}

.select2-container--default .select2-search--dropdown .select2-search__field{
    height: 30px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow{
    bottom: 1px;
    height: auto;
    width: 40px;
    @include flex-center;
    font-size: 12px;
    color: #7c858c;
}

.select2-container--default .select2-selection--single .select2-selection__arrow:before{
    position: relative;
    right: 10px;
    top: 0px;
    content: "\f107";
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    font-size: 16px;
}

.select2-container--default .select2-selection--single .select2-selection__arrow b{
    display:none;
}

.select2-container--default .select2-selection--single{
    border-radius: 0;
}


.default-dots{
    .owl-dots{
        position: relative;
        margin-top: 30px;
        @include flex-center;
        .owl-dot{
            height: 11px;
            width: 11px;
            border-radius: 50%;
            background-color: #cdcbcb;
            border:2px solid #cdcbcb;
            margin-left: 5px;
            &:first-child{
                margin-left: 0;
            }
            &.active{
                border: 2px solid var(--border-theme-color2);
                background-color: transparent;
            }
        }
    }
}

.default-nav {
    .owl-nav{
        display: flex;
        align-items: center;
        min-width: 110px;
    }
    .owl-next,
    .owl-prev {
        display: block;
        height: 54px;
        width: 54px;
        color: #191e24;
        border-radius: 50%;
        border: 2px solid #191e24;
        font-size: 20px;
        line-height:50px;
        font-weight: 700;
        text-align: center;
        opacity: .20;
        transition: all 500ms ease;
        margin: 0 10px 10px 0;
        &:last-child{
         margin-right: 0;   
        }
        &:hover {
            opacity: 1;
        }
    }
}