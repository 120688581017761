/***

====================================================================
    Blog Details
====================================================================

***/

.blog-details {
  position: relative;
  display: block;
}
.blog-details__left {
  position: relative;
  display: block;
}
.blog-details__img {
  position: relative;
  display: block;
  border-radius: 10px;
  img {
    width: 100%;
    border-radius: 10px;
  }
}
.blog-details__date {
  position: absolute;
  bottom: 0;
  right: 0;
  background-color: var(--theme-color1);
  text-align: center;
  padding: 21px 24px 20px;
  align-items: center;
  border-bottom-right-radius: 10px;
  border-top-left-radius: 10px;
  display: flex;
  flex-direction: column;
  .day {
    font-size: 16px;
    color: #fff;
    font-weight: var(--body-font-weight-bold);
    line-height: 16px;
  }
  .month {
    position: relative;
    display: block;
    font-size: 10px;
    font-weight: var(--body-font-weight-bold);
    color: #fff;
    line-height: 12px;
    text-transform: uppercase;
  }
}
.blog-details__content {
  position: relative;
  display: block;
  margin-top: 22px;
}
.blog-details__meta {
  position: relative;
  display: flex;
  align-items: center;
  li+li {
    margin-left: 18px;
  }
  li {
    a {
      font-size: 15px;
      color: #777;
      font-weight: 500;
      -webkit-transition: all 500ms ease;
      transition: all 500ms ease;
      &:hover {
        color: var(--theme-color1);
      }
      i {
        color: var(--theme-color1);
        margin-right: 6px;
      }
    }
  }
}
.blog-details__title {
  font-size: 30px;
  line-height: 40px;
  margin-top: 12px;
  margin-bottom: 21px;
  font-weight: var(--body-font-weight-bold);
}
.blog-details__bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  padding: 30px 0 30px;
  margin-top: 49px;
  border-top: 1px solid #ece9e0;
  @media only screen and (max-width: 767px){
    gap: 30px;
  }
  p {
    margin: 0;
  }
}
.blog-details__tags {
  span {
    color: #0e2207;
    font-size: 20px;
    margin-right: 14px;
    font-weight: var(--body-font-weight-bold);
  }
  a {
    position: relative;
    font-size: 12px;
    background-color: var(--theme-color2);
    color: var(--text-color-bg-theme-color2);
    display: inline-block;
    padding: 5px 30px 5px;
    font-weight: var(--body-font-weight-bold);
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    border-radius: 35px;
    &:hover {
      background-color: var(--theme-color1);
      color: var(--text-color-bg-theme-color1);
      text-decoration: none;
    }
  }
  a+a {
    margin-left: 6px;
  }
}
.blog-details__social-list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  a {
    position: relative;
    height: 43px;
    width: 43px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    color: var(--theme-light-background-text-color);
    background-color: var(--theme-light-background);
    font-size: 15px;
    border-radius: 50%;
    overflow: hidden;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    z-index: 1;
    &:hover {
      color: var(--text-color-bg-theme-color2);
      &:after {
        opacity: 1;
        -webkit-transform: scaleY(1);
        transform: scaleY(1);
      }
    }
    &:after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      right: 0;
      height: 100%;
      background-color: var(--theme-color2);
      -webkit-transition-delay: .1s;
      transition-delay: .1s;
      -webkit-transition-timing-function: ease-in-out;
      transition-timing-function: ease-in-out;
      -webkit-transition-duration: .4s;
      transition-duration: .4s;
      -webkit-transition-property: all;
      transition-property: all;
      opacity: 1;
      -webkit-transform-origin: top;
      transform-origin: top;
      -webkit-transform-style: preserve-3d;
      transform-style: preserve-3d;
      -webkit-transform: scaleY(0);
      transform: scaleY(0);
      z-index: -1;
    }
  }
  a+a {
    margin-left: 10px;
  }
}
.blog-details__pagenation-box {
  position: relative;
  display: block;
  overflow: hidden;
  margin-bottom: 53px;
}
.blog-details__pagenation {
  position: relative;
  display: block;
  li {
    position: relative;
    float: left;
    font-size: 20px;
    color: #0e2207;
    font-weight: var(--body-font-weight-bold);
    color: var(--theme-light-background-text-color);
    background-color: var(--theme-light-background);
    line-height: 30px;
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    max-width: 370px;
    width: 100%;
    padding-left: 60px;
    padding-right: 60px;
    padding-top: 52px;
    padding-bottom: 52px;
    border-radius: 10px;
    &:hover {
      background-color: var(--theme-color2);
      color: var(--text-color-bg-theme-color2);
    }
  }
  li+li {
    margin-left: 30px;
  }
}

/* Nav Links */
.nav-links {
  display: flex;
  justify-content: space-between;
  margin-bottom: 53px;
  @media only screen and (max-width: 767px){
    flex-direction: column;
    gap: 20px;
  }
  .prev {
    display: flex;
    align-items: center;
    height: 100%;
    width: calc(50% - 15px);
    margin-right: 30px;
    @media only screen and (max-width: 767px){
      width: 100%;
    }
    .thumb {
      margin-right: 20px;
    }
  }
  .next {
    display: flex;
    align-items: center;
    height: 100%;
    flex-direction: row-reverse;
    width: calc(50% - 15px);
    @media only screen and (max-width: 767px){
      width: 100%;
    }
    .thumb {
      margin-left: 20px;
    }
  }
  >div {
    -webkit-transition: all 500ms ease;
    transition: all 500ms ease;
    .thumb {
      display: inline-block;
      min-width: 60px;
      width: 60px;
      height: 60px;
      overflow: hidden;
      a {
        display: inline-block;
      }
    }
    >a {
      display: inline-block;
      word-wrap: break-word;
      white-space: -moz-pre-wrap;
      white-space: pre-wrap;
      font-size: 20px;
      line-height: 1.637;
      font-weight: var(--body-font-weight-bold);
      -webkit-transition: all 500ms ease;
      transition: all 500ms ease;
      color: var(--theme-light-background-text-color);
      background-color: var(--theme-light-background);
      padding: 52px 50px;
      border-radius: 10px;
      width: 100%;

      @media only screen and (max-width: 767px){
        padding: 30px;
      }

      &:hover {
        color: var(--text-color-bg-theme-color1);
        background-color: var(--theme-color1);
      }
    }
  }
}
