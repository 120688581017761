//Root 

:root {

  //theme color
  --theme-color1:  #191e24;
  --theme-color2: #00c7c4;
  --theme-color3: #7054e6;
  --theme-color4: #f89321;
  --theme-color5: #ef5c72;

  --bg-theme-color1: var(--theme-color1);
  --bg-theme-color2: var(--theme-color2);
  --bg-theme-color3: var(--theme-color3);
  --bg-theme-color4: var(--theme-color4);
  --bg-theme-color5: var(--theme-color5);

  --border-theme-color1: var(--theme-color1);
  --border-theme-color2: var(--theme-color2);
  --border-theme-color3: var(--theme-color3);
  --border-theme-color4: var(--theme-color4);
  --border-theme-color5: var(--theme-color5);

  --text-color-bg-theme-color1: #fff;
  --text-color-bg-theme-color2: #164333;
  --text-color-bg-theme-color3: #fff;
  --text-color-bg-theme-color4: #fff;
  --text-color-bg-theme-color5: #fff;


  //text heading color
  --text-color:         #7d8185;
  --headings-color:     var(--theme-color1);
  --link-color:         var(--theme-color1);
  --link-hover-color:   var(--theme-color1);

  //font family
  --text-font: 'DM Sans', sans-serif;
  --title-font: 'DM Sans', sans-serif;
  --style-font: 'Reey';

  //body
  --body-font-size:   18px;
  --body-line-height: 30px;
  --body-font-weight: 400;

  //heading font size + weight
  --line-height-heading-h1: 1em;
  --line-height-heading: 1.2em;
  --line-height-heading-small: 1.4em;

  --h1-font-size: 84px;
  --h2-font-size: 50px;
  --h3-font-size: 36px;
  --h4-font-size: 24px;
  --h5-font-size: 20px;
  --h6-font-size: 18px;

  --h1-font-weight: 700;
  --h2-font-weight: 700;
  --h3-font-weight: 700;
  --h4-font-weight: 700;
  --h5-font-weight: 700;
  --h6-font-weight: 700;

  //section sub title
  --sec-title-subtitle-color: var(--theme-color2);
  --sec-title-subtitle-font-size: 16px;
  --sec-title-subtitle-font-family: var(--text-font);
  --sec-title-subtitle-font-weight: 700;
  --sec-title-subtitle-line-height: 1.1em;

  //section title
  --sec-title-color:                 var(--headings-color);
  --sec-title-font-size:             var(--h2-font-size);
  --sec-title-font-family:           var(--title-font);
  --sec-title-font-weight:           var(--h2-font-weight);

  //news details page
  --theme-light-background: #f8f6f1;
  --theme-light-background-text-color: var(--headings-color);
  --theme-black:        #222429;


  //container
  --container-width: 1200px;
  --small-container-width: 1000px;
  --large-container-width: 1550px;
  --container-pt:    120px;
  --container-pb:    120px;
}
