
/*** 

====================================================================
Page Title
====================================================================

***/
@-webkit-keyframes "ripple" {
    70% {
        -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}
@keyframes "ripple" {
    70% {
        -webkit-box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 70px rgba(255, 255, 255, 0);
    }
    100% {
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
    }
}

.page-title {
    position: relative;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    padding: 100px 0 110px;
    min-height: 250px;
    &:before {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
        background: #131313;
        opacity: 0.8;
        content: "";
    }
    .title {
        font-size: 40px;
        color: #ffffff;
        margin-bottom: 17px;
    }
    .text {
        position: relative;
        color: #ffffff;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: 0.05em;
        max-width: 520px;
    }
}
.page-breadcrumb {
    position: relative;
    margin-top: 5px;
    li {
        position: relative;
        display: inline-block;
        margin-right: 12px;
        padding-right: 13px;
        color: #ffffff;
        font-size: 14px;
        font-weight: 400;
        text-transform: capitalize;
        &:after {
            position: absolute;
            content: "\f105";
            right: -6px;
            top: 1px;
            color: #ffffff;
            font-size: 14px;
            font-weight: 900;
            font-family: 'Font Awesome 5 Free';
            color: #ffffff;
        }
        &:last-child {
            &::after {
                display: none;
            }
            padding-right: 0px;
            margin-right: 0px;
        }
        a {
            color: var(--theme-color2);
            font-weight: 500;
            text-transform: capitalize;
            transition: all 0.3s ease;
            -moz-transition: all 0.3s ease;
            -webkit-transition: all 0.3s ease;
            -ms-transition: all 0.3s ease;
            -o-transition: all 0.3s ease;
            &:hover {
                color: #ffffff;
            }
        }
    }
}
.page-title.style-two {
    background-position: center center;
    .page-breadcrumb-outer {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        padding: 10px 0;
        -webkit-transform: translateY(100%);
        -ms-transform: translateY(100%);
        transform: translateY(100%);
        z-index: 8;
    }
    .page-breadcrumb {
        li {
            color: rgba(7, 7, 16, 1);
            font-weight: 600;
            &:after {
                color: rgba(7, 7, 16, 1);
            }
            a {
                color: rgba(7, 7, 16, .6);
                &:hover {
                    color: rgba(7, 7, 16, 1);
                }
            }
        }
    }
}
.play-now {
    position: relative;
    display: block;
    z-index: 9;
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    .icon {
        position: relative;
        display: inline-block;
        height: 70px;
        width: 70px;
        text-align: center;
        line-height: 70px;
        background-color: #ffffff;
        color: #ff6d2e;
        z-index: 1;
        padding-left: 5px;
        font-size: 14px;
        display: block;
        border-radius: 50%;
        -webkit-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
        -ms-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
        -o-box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
        box-shadow: 0 0px 10px 0 rgba(255, 255, 255, .3);
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
    }
    .ripple {
        position: absolute;
        top: 50%;
        left: 50%;
        height: 70px;
        width: 70px;
        -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
        border-radius: 50%;
        -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
        -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
        -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
        box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
        -webkit-animation: ripple 3s infinite;
        animation: ripple 3s infinite;
        &:before {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 70px;
            width: 70px;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            border-radius: 50%;
            -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
            -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
            -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
            -webkit-animation: ripple 3s infinite;
            animation: ripple 3s infinite;
            -webkit-animation-delay: .9s;
            animation-delay: .9s;
            content: "";
            position: absolute;
        }
        &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            height: 70px;
            width: 70px;
            -webkit-transform: translate(-50%, -50%);
            -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
            border-radius: 50%;
            -webkit-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
            -ms-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
            -o-box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
            box-shadow: 0 0 0 0 rgba(255, 255, 255, .6);
            -webkit-animation: ripple 3s infinite;
            animation: ripple 3s infinite;
            -webkit-animation-delay: .6s;
            animation-delay: .6s;
            content: "";
            position: absolute;
        }
    }
}
.background-image {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    background-repeat: repeat;
    background-position: center;
    background-size: cover;
}
