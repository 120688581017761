/*** 

====================================================================
		About Section
====================================================================

***/

.about-section {
    position: relative;
    padding: 120px 0 0px;
    .icon-dotted-map{
        top: 145px;
        left: -585px;
    }
    .icon-dotted-map-2{
        top: 20px;
        left: -5px;
    }
    .icon-paper-plan{
        left: -160px;
        top: 310px;
    }
    .icon-dotted-line {
        left: 280px;
        top: 300px;
    }
}

.about-section .content-column {
    position: relative;
    margin-bottom: 35px;
    z-index: 1;
    .inner-column {
        position: relative;
        padding-left: 60px;
        padding-top: 30px;
    }
    .sec-title {
        margin-bottom: 40px;
    }
    .btn-box {
        margin-top: 10px;
    }
}

.about-section .image-column {
    position: relative;
    margin-bottom: 35px;
    .inner-column {
        position: relative;
        padding-right: 50px;
        margin-left: -150px;
    }
    .image-1 {
        position: relative;
        max-width: 460px;
        margin-bottom: 0px;
        img {
            width: 100%;
        }
    }
    .image-2 {
        position: absolute;
        top: 0;
        right: 55px;
        border-radius: 30px;
        overflow: hidden;
        margin-bottom: 0;
        img {
            width: 100%;
            max-width: 300px;
        }
    }
}

.about-section .experience {
    position: absolute;
    bottom: -40px;
    right: 140px;
    width: 290px; 
    height: 101px;
    background-image: url(../images/icons/exp.png);
    padding: 24px 20px 20px 110px;
    font-size: 22px;
    line-height: 1.2em;
    color: #ffffff;
    font-weight: 700;
    box-shadow: 0 25px 40px rgba(0,0,0, .24) ;
    .count {
        position: absolute;
        left: 25px;
        top: 18px;
        font-size: 70px;
        line-height: 1em;
        font-weight: 700;
    }
    strong {
        position: relative;
        margin-top: 7px;
        font-size: 34px;
        line-height: 36px;
        color: var(--theme-color2);
        font-weight: 700;
        display: block;
    }
}



/*** 

====================================================================
		About Section Two
====================================================================

***/

.about-section-two{
    position: relative;
    padding: 120px 0 70px;
    .icon-e{
        left: -190px;
        top: 300px;
        width: 207px;
        height: 205px;
    }
    .icon-dots-2{
        left: -120px;
        bottom:70px;
    }    
    .content-column {
        position: relative;
        margin-bottom: 20px;
        z-index: 1;
        .inner-column {
            position: relative;
            padding-left: 40px;
        }
        .sec-title{
            margin-bottom: 70px;
            .text{
                margin-top: 45px;
            }
        }
    }
    .image-column{
        margin-bottom: 50px;
    }
}

.about-block{
    position: relative;
    margin-bottom: 30px;
    .inner-box{
        position: relative;
    }
    .info-text{
        position: relative;
        display: inline-block;
        margin-left: 40px;
        background-color: var(--bg-theme-color5);
        font-size: 17px;
        line-height: 1.2em;
        color: #fff;
        font-weight: 400;
        padding: 15px 20px;
        margin-bottom: 10px;
        &:before{
            position: absolute;
            left: 30px;
            bottom: -16px;
            border-right: 23px solid transparent;
            border-top: 16px solid var(--border-theme-color5);
            z-index: 1;
            content: "";
        }
    }
    .info-box{
        position: relative;
        background-color: #f1f1f1;
        max-width: 200px;
        padding: 40px 20px 35px;
        text-align: center;
        .thumb{
            height: 84px;
            width: 84px;
            border-radius: 50%;
            overflow: hidden;
            margin: 0 auto 18px;
        }
        .name{
            margin-bottom: 8px;
        }
        .designation{
            display: block;
            font-size: 12px;
            line-height: 1em;
            color: #81868a;
            text-transform: uppercase;
            letter-spacing: .2em;
        }
    }
    &.style-two{
        margin-top: 55px;
        .info-text{
            background-color: var(--bg-theme-color3);
            &:before{
                border-top-color: var(--border-theme-color3);
            }
        }
    }
}

/*** 

====================================================================
		About Section Three
====================================================================

***/

.about-section-three {
    position: relative;
    padding: 120px 0 70px;
    .icon-percent{
        left: -110px;
        top: -30px;
        z-index: 2;
    }
    .icon-star-1{
        top:-50px;
        left: 420px;
    }
    .icon-dots-4{
        left: 510px;
        top: 40px;
    }
    .icon-wave{
        left: -70px;
        bottom: 20px;
    }
    .icon-idea{
        left: 530px;
        top: 400px;
        z-index: 1;
    }
    .icon-dots-3{
        top: -80px;
        right: -25px;
    }
    .icon-dots-5 {
        top: 160px;
        right: -400px;
    }
    .icon-star-2{
        right: -130px;
        top: 190px;
    }
}

.about-section-three .content-column {
    position: relative;
    margin-bottom: 50px;
    z-index: 1;
    .inner-column {
        position: relative;
    }
    .sec-title {
        margin-bottom: 40px;
    }
    .btn-box{
        position: relative;
        padding-right: 200px;
        margin-top: 40px;
        .float-icon{
            position: absolute;
            right: 100%;
            top: 100%;
            transform: translateX(75px) translateY(-10px);
        }
    }
    .info-box{
        position: absolute;
        right: 0;
        bottom: -40px;
        border-radius: 0 50px 0 50px;
        border: 1px solid #dbe6e6;
        box-shadow: 0 20px 60px rgba(0,0,0, .07);
        padding: 30px 40px;
        .icon-box{
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            margin-bottom: 15px;
            .icon{
                font-size: 78px;
                line-height: 1em;
                color: var(--theme-color2);
                position: relative;
                top: 15px;
            }
            .count-box{
                font-size: 48px;
                color: var(--theme-color1);
                font-weight: 700;
            }
        }
        .counter-title{
            color: #7d8185;
            font-weight: 400;
            font-size: 20px;
            text-align: center;
            letter-spacing: .01em;
            margin-bottom: 0;
        }
    }
}

.about-section-three .image-column {
    position: relative;
    margin-bottom: 50px;
    .inner-column {
        position: relative;
        padding-right: 60px;
        margin-left: -180px;
        &::before{
            position: absolute;
            left: -25%;
            top: -40%;
            width: 1306px;
            height: 1232px;
            background-image: url(../images/icons/shadow.png);
            content: "";
        }
        .image{
            position: relative;
            margin-bottom: 0;
        }
    }

    .author-info{
        position: absolute;
        left:-70px;
        bottom: 60px;
        padding: 25px 50px 20px;
        background-color: #fff;
        border-radius: 0 50px 0 50px;
        box-shadow: 0 30px 80px rgba(0,0,0, .10);
        text-align: right;
        .name{
            font-size: 30px;
            line-height: 1em;
            margin-bottom: -3px;
        }
        .designation{
            font-size: 20px;
            color: var(--theme-color5);
        }
    }
}


/***

==================================================================
    About Section Four
==================================================================

***/

.about-section-four{
    position: relative;
    background-color: #f1f1f1;
    &:before{
        @include overlay;
        @include background;
        background-image: url(../images/icons/pattern-4.png);
        content: "";
    }
}

.about-section-four .content-column {
    position: relative;
    .inner-column {
        position: relative;
        padding: 110px 0 80px;
    }

    .sec-title {
        margin-bottom: 50px;
        .text {
            font-size: 18px;
            line-height: 34px;
            color: #81868a;
            max-width: 500px;
            margin-top: 40px;
        }
    }

    .contact-info {
        position: relative;
        padding-left: 70px;
        min-height: 50px;
        margin-bottom: 50px;
        &:hover {
            .icon {
                color: #fff;
                transform: scaleX(-1);
            }
        }
        .icon {
            @include absolute;
            @include flex-center;
            height: 50px;
            width: 50px;
            font-size: 18px;
            color: #ffffff;
            background-color: var(--bg-theme-color5);
            border-radius: 50%;
            line-height: 1em;
            transition: all 300ms ease;
        }
        .title {
            margin-bottom: 0;
            font-size: 18px;
            color: #5366c2;
        }
        .info{
            font-size: 16px;
            line-height: 32px;
            color: #7e7e7e;
            a{
                color: #242323;
                font-weight: 700;
                margin-left: 5px;
                text-decoration: underline;
                &:hover{
                    color: var(--theme-color5);
                }
            }
        }
    }

    .counter-info-box{
        position: relative;
        padding: 50px 60px 50px;
        background-color: #fff;
        box-shadow: 0 10px 60px rgba(0,0,0, .10);
        border-radius: 15px;
        max-width: 470px;
        .inner{
            position: relative;
            padding-left: 170px;
        }
        .count-box{
            position: absolute;
            left: 0;
            top: 0;
            font-size: 65px;
            color: #f16101;
            font-weight: 700;
            line-height: 1em;
        }
        .text{
            font-size: 18px;
            line-height: 30px;
            color: #7d8185;
        }
    }
}

.about-section-four .image-column {
    position: relative;

    .inner-column {
        position: relative;
        padding-top: 120px;
    }

    .image-box {
        position: relative;
        margin-right: -400px;

        .image {
            position: relative;
            margin-bottom: 0;
            width: 100%;

            img {
                width: 100%;
                min-height: 570px;
                object-fit: cover;
            }
        }
    }

    .caption-box {
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        max-width: 400px;
        background-color: #6956f9;
        display: flex;
        padding: 45px 20px 50px 50px;
        border-radius: 0 20px 0 0;
        .inner{
            position: relative;
            padding-left: 95px;
        }
        .icon{
            @include absolute;
            font-size: 74px;
            line-height: 1.2em;
            color: #ffffff;
        }
        .text {
            font-size: 18px;
            line-height: 26px;
            color: #ffffff;
            font-weight: 400;
        }
    }

}
