/*
 * typography.scss
 * -----------------------------------------------
*/
::selection {
  background: var(--theme-color1);
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: var(--theme-color1); /* Firefox */
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: var(--theme-color1); /* Safari */
  color: #fff;
  text-shadow: none;
}

:active,
:focus { outline: none !important; }

::-webkit-input-placeholder{color: #7c858c;}
::-moz-input-placeholder{color: #7c858c;}
::-ms-input-placeholder{color: #7c858c;}


body {
  background-color: #fff;
  background-attachment: fixed;
  -ms-word-wrap: break-word;
  word-wrap: break-word;
  counter-reset: my-sec-counter;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

p,.text{
  color: var(--text-color);
  font-size: var(--body-font-size);
  font-family: var(--text-font);
  font-weight: var(--body-font-weight);
  line-height: var(--body-line-height);
}

/* -------- Headings ---------- */
h1, h2, h3, h4, h5, h6 {
  color: var(--headings-color);
  font-family: var(--title-font);
  position:relative;
  line-height:var(--line-height-heading-);
  small,
  .small {
    font-weight: normal;
    line-height: 1;
    color: var(--headings-color);
  }
  a {
    color: inherit;
    font-weight: inherit;
  }
}


h1, 
h2, 
h3,
h4,
h5,
h6 {
  margin-bottom: 1rem;
  letter-spacing: -.04em;
}

h1 { font-size: var(--h1-font-size); font-weight: var(--h1-font-weight); line-height: var(--line-height-heading-h1); }
h2 { font-size: var(--h2-font-size); font-weight: var(--h2-font-weight); line-height: var(--line-height-heading); }
h3 { font-size: var(--h3-font-size); font-weight: var(--h3-font-weight); line-height: var(--line-height-heading); }
h4 { font-size: var(--h4-font-size); font-weight: var(--h4-font-weight); line-height: var(--line-height-heading); }
h5 { font-size: var(--h5-font-size); font-weight: var(--h5-font-weight); line-height: var(--line-height-heading); }
h6 { font-size: var(--h6-font-size); font-weight: var(--h6-font-weight); line-height: var(--line-height-heading-small); }

/* -------- Body Text ---------- */
table p {
  margin-bottom: 0;
}

p {
  margin-bottom: 20px;
  a:not(.button):not(.btn) {
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
}

/* -------- other ---------- */
a {
  color: var(--link-color);
  text-decoration: none;
  font-weight: var(--body-font-weight);
  cursor:pointer;
  @include transition(all .3s ease);
  &:hover,
  &:focus {
    color: inherit;
    text-decoration:none;
    outline:none;
  }
  b, strong {
    @include transition(all .3s ease);
  }
  img {
    border: none;
  }
}

pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset { margin-bottom: 10px; }


ol, ul {
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

b, strong {
  color: #333;
  font-weight: var(--body-font-weight-bold);
}

iframe { border: none !important; }
