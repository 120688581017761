/*** 

====================================================================
    Video Section
====================================================================

***/

.video-section{
    position: relative;
    padding: 150px 0 90px;
    .bg-image{
        &:before{
            @include overlay;
            background-color: var(--bg-theme-color1);
            content: "";
            opacity: .7;
        }
    }
    .outer-box{
        position: relative;
        display: flex;
        justify-content: space-between;
    }
    .title-box{
        position: relative;
        margin-bottom: 60px;
        .title{
            color: #fff;
            margin-bottom: 50px;
        }
        .text{
            color: #b8b8b8;
        }
    }
    .video-box{
        position: relative;
        display: flex;
        padding-right: 25px;
        .float-icon{
            position: relative;
            margin-right: 35px;
            top: 65px;
        }
        .play-btn{
            position: relative;
            display: inline-block;
            margin-top: 20px;
            i{
                position: relative;
                @include flex-center;
                height: 100px;
                width: 100px;
                font-size: 24px;
                background-color: var(--bg-theme-color5);
                color: #fff;
                padding-left: 5px;
                border-radius: 50%;
                margin: 0 auto 30px;
                transition: all 300ms ease;
                &:after{
                    position: absolute;
                    left: -25px;
                    top: -25px;
                    height: 150px;
                    width: 150px;
                    border: 1px solid rgba(255,255,255,.30);
                    border-radius: 50%;
                    content: "";
                    animation: zoom-one 2s infinite linear;
                }
                &:hover{
                    background-color: #fff;
                    color: var(--theme-color5);
                }
            }
        }
    }
}
