
/*** 

====================================================================
    News Section
====================================================================

***/

.news-section {
    position: relative;
    padding: 120px 0 70px;
    overflow: hidden;
}

.news-block {
    position: relative;
    margin-bottom: 50px;
    .inner-box {
        position: relative;
        transition: all 300ms ease;
        &:hover .image-box .image a:after {
            left: 0;
            right: 0;
            opacity: 0;
            transition: all 400ms linear;
        }
        &:hover .image img {
            transform: scale(1.1);
        }
    }
    .image-box {
        position: relative;
        .image {
            position: relative;
            overflow: hidden;
            margin-bottom: 0;
            img {
                display: block;
                width: 100%;
                border-radius: 15px;
                transition: all 400ms ease;
            }
            a:after {
                background: rgba(255, 255, 255, .3);
                bottom: 0;
                content: "";
                left: 50%;
                position: absolute;
                right: 51%;
                top: 0;
                opacity: 1;
                pointer-events: none;
                transition: all 400ms linear;
            }
        }
        .date {
            position: absolute;
            right: 30px;
            top: 0px;
            background: var(--theme-color5);
            color: #fff;
            z-index: 1;
            @include vertical-center;
            height: 60px;
            width: 50px;
            align-items: center;
            font-size: 12px;
            font-weight: 400;
            line-height: 16px;
            text-transform: uppercase;
            border-radius: 0 0 50px 50px;
            letter-spacing: 1px;
            b {
                display: block;
                font-weight: 600;
                font-size: 20px;
                line-height: 16px;
                color: #fff;
            }
        }
    }
    .content-box{
        position: relative;
        margin-top: -100px;
        padding: 0 20px;
        .content{
            position: relative;
            padding: 20px 30px 1px;
            background-color: #fff;
            border-radius: 15px 15px;
            box-shadow: 0 10px 60px rgba(0, 0, 0, .07);
        }
        .post-info {
            position: relative;
            display: inline-flex;
            flex-wrap: wrap;
            margin-bottom: 10px;
            li {
                position: relative;
                font-size: 14px;
                line-height: 24px;
                color: #7d8185;
                font-weight: 400;
                margin-right: 14px;
                i {
                    margin-right: 2px;
                    color: var(--theme-color2);
                    font-size: 14px;
                }
            }
        }
        .title{
            margin-bottom: 25px;
            &:hover {
                color: var(--theme-color5);
            }
        }
    }
    .read-more {
        font-size: 14px;
        line-height: 30px;
        color: #7d8185;
        padding: 10px 30px;
        font-weight: 400;
        margin-left: -30px;
        margin-right: -30px;
        border-top: 1px solid #dae6e6;
        display: flex;
        align-items: center;
        justify-content: space-between;
        i {
            margin-left: 10px;
            color: var(--theme-color1);
            font-size: 16px;
            transition: all 100ms linear;
        }
        &:hover{
            color: var(--theme-color4);
            i{
                color: var(--theme-color4);
            }
        }
    }
}

/*** 

====================================================================
    News Section Two
====================================================================

***/

.news-section-two{
    position: relative;
    padding: 120px 0 70px;
    overflow: hidden;
    .icon-dotted-map-4{
        left:-640px;
        top:0;
    }
   
    .title-column{
        position: relative;
        margin-bottom: 50px;
    }
    .carousel-column{
        position: relative;
        margin-bottom: 20px;
        .carousel-outer{
            position: relative;
            margin-right: -410px;
        }
        .news-block{
            padding: 0 15px 30px;
            margin-bottom: 0;
            .content-box .content{
                box-shadow: 0 10px 20px rgb(0 0 0 / 7%);
            }
        }
    }

    .news-carousel .owl-nav{
        bottom: 190px;
        left: -400px;
    }
}

.news-carousel .owl-nav{
    display: none;
}

.news-carousel .owl-dots {
    position: absolute;
    left: -400px;
    bottom: 160px;
    display: flex;
    align-items: center;
    .owl-dot{
        display: block;
        margin-right: 2px;
        height: 16px;
        width: 16px;
        border-radius: 50%;
        background-color: #d1d2d3;
        border: 3px solid #fff;
        font-size: 20px;
        line-height: 48px;
        font-weight: 700;
        text-align: center;
        transition: all 500ms ease;
        &.active,
        &:hover {
            border-color: var(--border-theme-color2);
            background-color: #fff;
        }
    }
}