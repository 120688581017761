/***

====================================================================
        Contact
====================================================================

***/
.contact-details__info {
  position: relative;
  display: block;
  margin-top: 41px;
  li {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    .icon {
      height: 80px;
      width: 80px;
      background-color: var(--theme-color3);
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      -ms-flex-align: center;
      align-items: center;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      -webkit-transition: all 500ms ease;
      transition: all 500ms ease;
      span {
        color: var(--text-color-bg-theme-color1);
        font-size: 25px;
        -webkit-transition: all 500ms ease;
        transition: all 500ms ease;
      }
    }
    &:hover {
      .icon {
        background-color: var(--theme-color5);
        span {
          color: var(--text-color-bg-theme-color5);
        }
      }
      .text {
        a {
          color: var(--notech-base);
          span {
            color: var(--notech-gray);
          }
        }
      }
    }
    .text {
      margin-left: 30px;
      p {
        font-size: 14px;
        line-height: 24px;
      }
      a {
        font-size: 18px;
        color: var(--notech-black);
        -webkit-transition: all 500ms ease;
        transition: all 500ms ease;
      }
      span {
        font-size: 20px;
        color: var(--notech-black);
      }
    }
  }
  li+li {
    margin-top: 19px;
  }
}