/*** 

====================================================================
    Features Section
====================================================================

***/

.features-section {
    position: relative;
    padding: 80px 0 30px;
    background-color: var(--bg-theme-color3) !important;
    z-index: 2;
    &:before{
        @include overlay;
        @include background;
        background-image: url(../images/icons/pattern-1.png);
        content: "";
    }
}

.feature-block {
    position: relative;
    margin-bottom: 50px;
    z-index: 1;
    &:last-child {
        .inner-box:before{
            display: none;
        }
    }
    .inner-box {
        position: relative;
        overflow: hidden;
        padding-left: 80px;
        min-height: 64px;
        margin-left: 10px;
        &:before{
            position: absolute;
            right: 0;
            top: 0;
            height: 64px;
            width: 1px;
            background-color: #fff;
            opacity: .30;
            content: "";
        }
        &:hover{
            .icon{
                transform: scaleX(-1)
            }
        }
    }
    .icon {
        @include absolute;
        @include flex-center;
        color: #fff;
        font-size: 64px;
        font-weight: 900;
        transition: all 200ms linear;
    }
    .title {
        margin-bottom: 0;
        font-size: 20px;
        line-height: 30px;
        color: #fff;
        font-weight: 500;
        letter-spacing: 0;
    }
    .text {
        position: relative;
    }
}


/*** 

====================================================================
    Features Section Two
====================================================================

***/

.features-section-two{
    position: relative;
    padding: 120px 0 70px;
    z-index: 2;
    .icon-shape-1{
        left: -820px;
        top: -350px;
    }
    .icon-shape-2 {
        right: -940px;
        top: 25px;
    }
    .icon-e{
        left: -330px;
        bottom: 145px;
        width: 140px;
        height: 137px;
    }
}

.feature-block-two{
    position: relative;
    margin-bottom: 50px;
    z-index: 1;
    .inner-box {
        position: relative;
        overflow: hidden;
        text-align: center;
        &:hover{
            .image img{
                filter: drop-shadow(0 20px 5px rgba(0,0,0,.20));
                transform: translateY(-15px);
            }
            .title {
                color: var(--theme-color5);
            }
        }
    }
    .image {
        position: relative;
        margin-bottom: 25px;
        img{
            transition: all 300ms ease;
        }
    }
    .title {
        margin-bottom: 20px;
    }
    .text {
        position: relative;
        max-width: 300px;
        margin: 0 auto;
    }
}
