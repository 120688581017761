
/***

==================================================================
	Main Footer
==================================================================

***/

.main-footer{
	position: relative;
	background-color: var(--bg-theme-color1);
	.bg-image{
		@include overlay;
		@include absolute;
		@include background;
	}
}

/* Widget Section */
.main-footer {
	.widgets-section{
		position: relative;
		padding:120px 0 80px;
	}
	.footer-column {
		position: relative;
		margin-bottom: 40px;
	}
	.footer-widget {
		position: relative;
	}

	.widget-title {
		position: relative;
		font-weight: 700;
		color: #ffffff;
		margin-bottom: 25px;
	}
}


.main-footer .about-widget{
	position: relative;
	.logo {
		position: relative;
		margin-bottom: 22px;
	}
	.text{
		font-size:20px;
		color: #ffffff;
		line-height: 30px;
		font-weight: 700;
		margin-bottom: 20px;
	}
}

/*=== User LInks ===*/

.user-links {
	position: relative;
	li {
		position: relative;
		font-size: 16px;
		line-height: 26px;
		color: #989ea6;
		font-weight: 400;
		margin-bottom: 10px;
		&:last-child {
			margin-bottom: 0;
		}
		a {
			position: relative;
			display: inline-block;
			color: inherit;
			transition: all 300ms ease;
			&:hover {
				color: #FFFFFF;
			}
			&:before {
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0;
				height: 1px;
				background-color: var(--bg-theme-color2);
				content: "";
				transition: all 300ms ease;
			}
			&:hover:before {
				width: 100%;
			}
		}
	}
}

/* Contact Widget */
.contact-widget{
	position: relative;
	.contact-info {
		position: relative;
		margin-bottom: 25px;
		li {
			position: relative;
			padding-left: 38px;
			font-size: 16px;
			line-height: 24px;
			color: #989ea6;
			font-weight: 500;
			margin-bottom: 10px;
			transition: all 300ms ease;
			a{
				color: #989ea6;
				font-weight: 500;
				transition: none;
			}
			i {
				position: absolute;
				left: 0;
				top: 0;
				color: var(--theme-color4);
				line-height: 26px;
				width: 20px;
				font-size: 14px;
				text-align: center;
				transition: all 300ms ease;
			}
			&:hover{
				color: var(--theme-color2);
			}
		}
	}
}


/* Subscribe Form */
.subscribe-form {
	position: relative;
	.form-group {
		position: relative;
		margin-bottom: 0;
		input[type=text],
		input[type=email] {
			position: relative;
			display: block;
			height: 64px;
			width: 100%;
			font-size: 14px;
			line-height: 30px;
			color: #797f7d;
			padding: 17px 30px;
			padding-right: 60px;
			background: #ffffff;
			border-radius: 50px;
			transition: all 300ms ease;
		}
		.theme-btn {
			position: absolute;
			right: 12px;
			top: 12px;
			display: block;
			text-align: center;
			height: 40px;
			width: 40px;
			padding: 0;
			color: #ffffff;
			font-weight: 700;
			line-height: 40px;
			background-color: var(--bg-theme-color5);
			border-radius: 50%;
		}
	}
}


/*=== Footer Bottom ===*/
.footer-bottom {
	position: relative;
	width: 100%;
	.inner-container {
		position: relative;
		text-align: center;
		padding: 25px 15px;
		border-top: 1px solid rgba(255,255,255, .10);
	}
	.copyright-text {
		position: relative;
		font-size: 16px;
		color: #989ea6;
		line-height: 25px;
		a {
			color: inherit;
			&:hover {
				color: #ffffff;
			}
		}
	}
}