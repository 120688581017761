.payment-method {
	.accordion-box {
		.block {
			.acc-content {
				.payment-info {
					position: relative;
					display: block;
					background-color: #fff;
					padding: 30px 30px 10px 30px;
				}
			}
			background: #f4f5f4;
			box-shadow: none;
			margin-bottom: 20px;
			&:last-child {
				margin-bottom: 0px;
			}
			.acc-btn {
				padding: 19px 30px 22px 30px;
				.icon-outer {
					transform: translateY(-50%);
					position: absolute;
					top: 50%;
					right: 30px;
					height: auto;
					/* line-height: 65px; */
					font-size: 28px;
					color: #1e2434;
				}
				&.active {
					.icon-outer {
						color: var(--text-color-bg-theme-color2);
					}
				}
			}
		}
	}
	.payment-method {
		h3 {
			margin-bottom: 32px;
		}
	}
}
