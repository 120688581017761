
/*** 

====================================================================
    Fun Fact Section
====================================================================

***/

.fun-fact-section{
    position: relative;
    padding: 120px 0 90px;
}

.counter-block {
    position: relative;
    margin-bottom: 30px;
    .inner {
        position: relative;
        background-color: #fff;
        text-align: center;
        padding: 30px 30px 30px;
        box-shadow: 0 10px 60px rgba(0,0,0, .07);
        border-radius: 10px;
        transition: all 300ms ease;
        &:hover{
            transform: translateY(-15px);
            .icon{
                background-color: var(--bg-theme-color1);
                color: var(--bg-theme-color2);
                border-radius: 50%;
            }
            .counter-title{
                background-color: var(--bg-theme-color1);
                color: #fff;
            }
        }
    }
    .count-box {
        font-size: 48px;
        color: var(--theme-color2);
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
        font-family: var(--title-font);
        margin-bottom: 12px;
        letter-spacing: -.01em;
        .count-text {
            font-size: 48px;
            font-weight: 700;
            line-height: 1em;
        }
    }
    .counter-title {
        display: block;
        padding: 10px 15px;
        font-size: 12px;
        line-height: 18px;
        color: #7d8185;
        background-color: #f1f5f5;
        border-radius: 10px;
        font-weight: 500;
        letter-spacing: .1em;
        text-transform: uppercase;
        margin-bottom: 0;
        transition: all 300ms ease;
    }
    &:nth-child(4n + 2){.count-box{color: var(--theme-color4);}}
    &:nth-child(4n + 3){.count-box{color: var(--theme-color3);}}
    &:nth-child(4n + 4){.count-box{color: var(--theme-color5);}}
}

/*** 

====================================================================
    Fun Fact Section Two
====================================================================

***/

.fun-fact-section-two{
    position: relative;
    &:before{
        @include absolute;
        height: 50%;
        width: 100%;
        background-color: var(--bg-theme-color2);
        content: "";
    }
    .fact-counter{
        position: relative;
        padding: 75px 0 20px;
        &:before {
            @include absolute-right;
            height: 100%;
            width: 1000%;
            background-color: #e7e8ea;
            background-image: url(../images/icons/pattern-9.jpg);
            background-repeat: repeat;
            content: "";
        }
    }
}

// Counter Block Two 

.counter-block-two{
    position: relative;
    margin-bottom: 50px;
    .inner {
        position: relative;
        padding-left: 80px;
        min-height: 64px;
        transition: all 300ms ease;
        &:hover{
            .icon{
                transform:scaleX(-1);
                color: var(--theme-color2)
            }
        }
    }
    .icon {
        @include absolute;
        color: var(--theme-color1);
        font-size: 64px;
        line-height: 1em;
        transition: all 300ms ease;
        z-index: 1;
        margin-bottom: 20px;
    }
    .count-box {
        font-size: 46px;
        color: var(--theme-color1);
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
        font-family: var(--title-font);
        margin-bottom: 5px;
        .count-text {
            font-size: 46px;
            color: var(--theme-color1);
            font-weight: 700;
            line-height: 1em;
        }
    }

    .counter-title {
        display: block;
        font-size: 12px;
        line-height: 30px;
        color: #808287;
        letter-spacing: .1em;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}

// Counter Block Three
.counter-block-three{
    position: relative;
    border-right: 1px solid rgba(255,255,255, .1);
    &:last-child{
        border-right: 0;
    }
    &:before{
        position: absolute;
        left:50%;
        top: 0;
        width: 0;
        height: 4px;
        background-color: var(--border-theme-color2);
        transition: all 300ms ease;
        content: "";
    }
    &:hover{
        &:before{
            left: 0;
            width: 100%;
        }
    }
    .inner {
        position: relative;
        padding: 120px 0 10px;
        text-align: center;
        transition: all 300ms ease;
    }
    .count-box {
        font-size: 46px;
        color: #fff;
        font-weight: 700;
        line-height: 1em;
        text-transform: uppercase;
        font-family: var(--title-font);
        margin-bottom: 15px;
        .count-text {
            font-size: 46px;
            color: #fff;
            font-weight: 700;
            line-height: 1em;
        }
    }
    .counter-title {
        display: block;
        font-size: 12px;
        line-height: 30px;
        color: #fff;
        letter-spacing: .1em;
        font-weight: 500;
        text-transform: uppercase;
        margin-bottom: 0;
    }
}