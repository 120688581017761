

/*** 

====================================================================
      Mobile Menu
====================================================================

***/

.mobile-nav-toggler {
    position: relative;
    font-size: 24px;
    line-height: 20px;
    cursor: pointer;
    color: var(--theme-color1);
    display: none;
    top: 0px;
    margin-left: 25px;
    order: 8;
}

.mobile-menu {
    position: fixed;
    right: 0;
    top: 0;
    width: 300px;
    max-width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    z-index: 999999;
    .menu-backdrop {
        position: fixed;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background: rgba(0, 0, 0, 0.7);
        opacity: 0;
        visibility: hidden;
    }
    .upper-box {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        width: 100%;
        padding: 20px 20px;
    }
    .close-btn {
        position: relative;
        top: -10px;
        text-align: center;
        font-size: 18px;
        color: #ffffff;
        cursor: pointer;
        z-index: 10;
        transition: all 0.5s ease;
        transform: translateY(-50px);
        &:hover {
            opacity: 0.5;
        }
    }
    .nav-logo {
        position: relative;
        text-align: left;
        width: 100%;
        img {
            max-height: 40px;
        }
    }
    .menu-box {
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        left: 0px;
        top: 0px;
        width: 100%;
        height: 100%;
        max-height: 100%;
        overflow-y: auto;
        background: var(--bg-theme-color1);
        padding: 0px 0px;
        z-index: 5;
        opacity: 0;
        visibility: hidden;
        border-radius: 0px;
        transform: translateX(101%);
    }
}

.mobile-menu-visible {
    overflow: hidden;
    .mobile-menu {
        opacity: 1;
        visibility: visible;
        .menu-backdrop {
            opacity: 1;
            visibility: visible;
            transition: all 0.4s ease;
        }
        .menu-box {
            opacity: 1;
            visibility: visible;
            transition: all 0.4s ease 200ms;
            transform: translateX(0%);
        }
        .close-btn {
            transform: translateY(0px);
        }
    }
    .scroll-to-top {
        opacity: 0;
        visibility: hidden;
    }
}

.mobile-menu .navigation {
    position: relative;
    display: block;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    li {
        position: relative;
        display: block;
        border-bottom: 1px solid rgba(255, 255, 255, 0.10);
        >ul>li:last-child {
            border-bottom: none;
        }
        >ul>li:first-child {
            border-top: 1px solid rgba(255, 255, 255, 0.10);
        }
        >a {
            position: relative;
            display: block;
            line-height: 24px;
            padding: 10px 20px;
            font-size: 16px;
            color: #ffffff;
            font-weight: 400;
            text-transform: capitalize;
            
        }
        &:hover>a,
        &.current>a {
            color: var(--theme-color2);
        }
        &.dropdown .dropdown-btn {
            position: absolute;
            right: 0px;
            top: 0px;
            width: 44px;
            height: 44px;
            text-align: center;
            font-size: 16px;
            line-height: 44px;
            color: #ffffff;
            cursor: pointer;
            z-index: 5;
            &:after {
                content: '';
                position: absolute;
                left: 0px;
                top: 10px;
                width: 1px;
                height: 24px;
                border-left: 1px solid rgba(255, 255, 255, 0.10);
            }
            &.active i:before {
                content: "\f106";
            }
        }
    }
}

.mobile-menu .navigation li>ul,
.mobile-menu .navigation li>ul>li>ul {
    display: none;
}

.mobile-menu .navigation li>ul>li {
    padding-left: 20px;
}


.contact-list-one {
    position: relative;
    padding: 30px 20px 20px;
    li {
        position: relative;
        margin-bottom: 20px;
        &:last-child {
            margin-right: 0;
        }
        .contact-info-box {
            position: relative;
            padding-left: 54px;
            font-size: 14px;
            line-height: 24px;
            color: #ffffff;

            a {
                color: #ffffff;
                transition: all 300ms ease;
            }

            .icon {
                position: absolute;
                left: 0;
                top: 0;
                font-size: 34px;
                line-height: 50px;
                color: var(--theme-color2);
            }

            .title {
                display: block;
                font-size: 12px;
                color: #b2c1c0;
                font-weight: 400;
                text-transform: uppercase;
            }

        }
    }
}


.mobile-menu .social-links {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--bg-theme-color1);
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.10);
    margin-top: auto;
    li {
        position: relative;
        text-align: center;
        width: 100%;
        border-right: 1px solid rgba(255, 255, 255, 0.10);
        a {
            position: relative;
            display: block;
            line-height: 50px;
            height: 50px;
            font-size: 14px;
            text-align: center;
            color: #ffffff;
            transition: all 300ms ease;
            &:hover {
                color: var(--theme-color2);
            }
        }
    }
}




